import React from 'react'
import styled from '@emotion/styled'

// Components
import CurvedImage from '../molecules/curved-image'
import TagImage from '../molecules/tag-image'

// Styles
const Container = styled.section`
  position: relative;
  height: 90vh;
  overflow: hidden;
  z-index: 1;
`
const TagContainer = styled.section`
  position: absolute;
  bottom: 20%;
  left: 5%;
  @media (max-width: 500px) {
    left: 0;
    bottom: 15%;
  }
`

const Masthead = ({mastheadImage, tagImage, tagTitle, tagType, fill1='#ffffff', fill2='#ffffff', fill2Opacity='0.57'}) => (
  <Container>
    <CurvedImage fill1={fill1} fill2={fill2} fill2Opacity={fill2Opacity}>
      {mastheadImage}
    </CurvedImage>
    <TagContainer>
      <TagImage image={tagImage} title={tagTitle} type={tagType}/>
    </TagContainer>
  </Container>
)

export default Masthead