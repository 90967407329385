import React from 'react'
import styled from '@emotion/styled'

// Styles
const H5 = styled.h5`
  color: #70926E;
  font-family: 'Poiret One';
  font-size: 1.125em;
  margin: 0;
`

const Subtitle1 = ({children, white}) => (
  <H5>{children}</H5>
)

export default Subtitle1