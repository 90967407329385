import React from 'react'
import styled from '@emotion/styled'

// Styles
const H3 = styled.h3`
  color: #70926E;
  font-family: 'Poiret One';
  font-size: 2em;
  margin: 0;
`

const Header3 = ({children, style}) => (
  <H3 style={style}>{children}</H3>
)

export default Header3