import React from 'react'
import styled from '@emotion/styled'

// Components
import CurveWhite from '../atoms/curve-white'

// Styles
const Container = styled.section`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const CurvedImage = ({ children, fill1, fill2, fill2Opacity }) => (
  <Container>
    {children}
    <CurveWhite fill1={fill1} fill2={fill2} fill2Opacity={fill2Opacity}/>
  </Container>
)

export default CurvedImage