import React from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'


// Components
import Header3 from '../atoms/header-3'
import Subtitle1 from '../atoms/subtitle-1'

// Styles
const Container = styled(animated.section)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  background: white;
  padding: 5px;
  height: 60px;
  @media (max-width: 500px) {
    width: 100%;
    min-width: 200px;
  }
`
const ContainerImage = styled.section`
  width: 125px;
  height: 100%;
  @media (max-width: 500px) {
    display: none;
  }
`
const ContainerText = styled.section`
  width: auto;
  margin-left: 30px;
  margin-right: 30px;
`

const TagImage = ({image, title, type}) => {
    const props = useSpring({ transform: `translateY(0px)`, opacity: 1, from: { transform: `translateY(30px)`, opacity: 0 } })
    return(
      <Container style={props}>
        <ContainerImage>
          {image}
        </ContainerImage>
        <ContainerText>
          <Header3>{title}</Header3>
          <Subtitle1>{type}</Subtitle1>
        </ContainerText>
      </Container>
    )
  }


export default TagImage